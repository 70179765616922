@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat.woff2") format("woff2"), url("../fonts/montserrat.woff") format("woff"), url("../fonts/montserrat.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserratmedium.woff2") format("woff2"), url("../fonts/montserratmedium.woff") format("woff"), url("../fonts/montserratmedium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserratbold.woff2") format("woff2"), url("../fonts/montserratbold.woff") format("woff"), url("../fonts/montserratbold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  min-height: 100vh;
  font-size: 12px;
  font-weight: 400;
  font-family: Montserrat, Arial, sans-serif;
  line-height: 1.3;
  color: #180056;
  background-color: #fff;
}
a {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s ease;
}
a:hover {
  color: inherit;
  text-decoration: none;
}
ol,
ul {
  list-style-position: inside;
}
.wrap {
  width: 100%;
  max-width: 910px;
  margin: 0 auto;
  padding: 0 15px;
}
header .header__top {
  padding: 10px 0;
  margin-bottom: 20px;
  background-color: #E6EFF8;
}
header .header__burger {
  display: none;
  position: relative;
  width: 25px;
  height: 16px;
}
header .header__burger span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #180056;
  transition: all 0.3s ease;
}
header .header__burger span:nth-child(2) {
  top: 7px;
}
header .header__burger span:nth-child(3) {
  top: 14px;
}
header .header__nav {
  color: #180056;
  font-size: 12px;
  font-weight: 700;
}
header .header__nav ul {
  list-style: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
header .header__nav ul li {
  position: relative;
  text-align: center;
}
header .header__nav ul li:before {
  display: block;
  content: '';
  position: absolute;
  bottom: 4px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #180056;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}
header .header__nav ul li.current:before,
header .header__nav ul li:hover:before {
  opacity: 1;
  visibility: visible;
  bottom: 0;
}
main {
  position: relative;
  min-height: calc(100vh - 86px);
}
article {
  flex: 1;
}
aside {
  position: absolute;
  width: 175px;
  top: 0;
  left: -160px;
  padding-right: 15px;
}
aside .sidebar-block {
  margin-bottom: 25px;
}
aside .sidebar-block__title {
  display: block;
  font-weight: 700;
  font-size: 14px;
  color: #180056;
  padding: 3px 0;
  margin-bottom: 8px;
}
aside .sidebar-block__list {
  list-style: none;
}
aside .sidebar-block__list li {
  margin-bottom: 8px;
}
aside .sidebar-block__list li.current a {
  text-decoration: none;
}
aside .sidebar-block__list a {
  text-decoration: underline;
  font-weight: 500;
  color: #180056;
}
aside .sidebar-block__list a:hover {
  text-decoration: none;
}
.banner {
  width: 100%;
  height: auto;
  margin-bottom: 15px;
}
.banner img {
  width: 100%;
  height: auto;
}
.content {
  margin-bottom: 30px;
}
.content > * {
  margin-bottom: 15px;
}
.content h1,
.content h2,
.content h3,
.content h4,
.content h5,
.content h6 {
  text-align: center;
  font-weight: 700;
  color: #180056;
}
.content h1 {
  font-size: 16px;
}
.content h2 {
  font-size: 14px;
}
.content h3,
.content h4,
.content h5,
.content h6 {
  font-size: 12px;
}
.content ul,
.content ol {
  padding-left: 20px;
}
.content img.full {
  width: 100%;
}
.content img.left {
  float: left;
  margin-right: 15px;
}
.content img.right {
  float: right;
  margin-left: 15px;
}
.content img.center {
  display: block;
  max-width: 100%;
  margin: 0 auto 12px;
}
.content table {
  max-width: 100%;
  margin: 0 auto 15px;
  border-collapse: collapse;
  background-color: #E6EFF8;
}
.content table td {
  border: 1px solid #180056;
  padding: 8px 20px;
}
.content .review {
  position: relative;
  background-color: #E6EFF8;
  border-radius: 5px;
  padding: 5px 40px 5px 10px;
}
.content .review:after {
  display: block;
  content: '';
  position: absolute;
  width: 30px;
  height: 30px;
  right: 10px;
  top: 5px;
  background-image: url('../img/review-icon.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
footer .footer__copyright {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  color: #180056;
  padding: 8px 15px;
  background-color: #E6EFF8;
}
@media screen and (max-width: 1240px) {
  aside {
    position: relative;
    width: 100%;
    left: 0;
    top: auto;
  }
  aside .sidebar-block__title {
    text-align: center;
  }
}
@media screen and (max-width: 768px) {
  header {
    background-color: #E6EFF8;
  }
  header .header__burger {
    display: block;
  }
  header .header__burger.active span:nth-child(1) {
    transform: rotate(33deg);
    transform-origin: left;
  }
  header .header__burger.active span:nth-child(2) {
    width: 0;
  }
  header .header__burger.active span:nth-child(3) {
    transform: rotate(-33deg);
    transform-origin: left;
  }
  header .header__nav {
    position: absolute;
    top: -20px;
    left: 45px;
    background-color: #E6EFF8;
    padding: 5px;
    max-width: 150px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    z-index: 9999;
  }
  header .header__nav.active {
    top: 10px;
    opacity: 1;
    visibility: visible;
  }
  header .header__nav ul {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  header .header__nav ul li {
    margin-bottom: 10px;
  }
  main {
    flex-direction: column;
  }
  article {
    flex: none;
  }
  footer {
    background-color: #E6EFF8;
  }
}
@media screen and (max-width: 480px) {
  .content table td {
    min-width: auto;
    padding: 8px 15px;
  }
  .content img.left {
    float: none;
    margin-right: 0;
    width: 100%;
  }
  .content img.right {
    float: none;
    margin-left: 0;
    width: 100%;
  }
  .content ul,
  .content ol {
    padding-left: 0;
  }
}
