@media screen and (max-width: 1240px) {
  aside {
    position: relative;
    width: 100%;
    left: 0;
    top: auto;

    .sidebar-block {
      &__title {
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  header {
    background-color: @gray;

    .header {
      &__burger {
        display: block;

        &.active {
          span {
            &:nth-child(1) {
              transform: rotate(33deg);
              transform-origin: left;
            }
            &:nth-child(2) {
              width: 0;
            }
            &:nth-child(3) {
              transform: rotate(-33deg);
              transform-origin: left;
            }
          }
        }
      }

      &__nav {
        position: absolute;
        top: -20px;
        left: 45px;
        background-color: @gray;
        padding: 5px;
        max-width: 150px;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        opacity: 0;
        visibility: hidden;
        transition: all .3s ease;
        z-index: 9999;

        &.active {
          top: 10px;
          opacity: 1;
          visibility: visible;
        }
        ul {
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;

          li {
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  main {
    flex-direction: column;
  }

  article {
    flex: none;
  }

  footer {
    background-color: @gray;
  }
}

@media screen and (max-width: 480px) {
  .content {
    table {
      td {
        min-width: auto;
        padding: 8px 15px;
      }
    }
    img {
      &.left {
        float: none;
        margin-right: 0;
        width: 100%;
      }
      &.right {
        float: none;
        margin-left: 0;
        width: 100%;
      }
    }
    ul, ol {
      padding-left: 0;
    }
  }
}