@import "fonts";

@gray: #E6EFF8;
@dark-blue: #180056;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  min-height: 100vh;
  font-size: 12px;
  font-weight: 400;
  font-family: Montserrat, Arial, sans-serif;
  line-height: 1.3;
  color: @dark-blue;
  background-color: #fff;
}
a {
  color: inherit;
  text-decoration: none;
  transition: all .3s ease;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}
ol, ul {
  list-style-position: inside;
}

.wrap {
  width: 100%;
  max-width: 910px;
  margin: 0 auto;
  padding: 0 15px;
}

header {
  .header {
    &__top {
      padding: 10px 0;
      margin-bottom: 20px;
      background-color: @gray;
    }
    &__burger {
      display: none;
      position: relative;
      width: 25px;
      height: 16px;

      span {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: @dark-blue;
        transition: all .3s ease;

        &:nth-child(2) {
          top: 7px;
        }
        &:nth-child(3) {
          top: 14px;
        }
      }
    }
    &__nav {
      color: @dark-blue;
      font-size: 12px;
      font-weight: 700;

      ul {
        list-style: none;
        display: flex;
        justify-content: space-around;
        align-items: center;

        li {
          position: relative;
          text-align: center;

          &:before {
            display: block;
            content: '';
            position: absolute;
            bottom: 4px;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: @dark-blue;
            opacity: 0;
            visibility: hidden;
            transition: all .3s ease;
          }
          &.current,
          &:hover {
            &:before {
              opacity: 1;
              visibility: visible;
              bottom: 0;
            }
          }
        }
      }
    }
  }
}

main {
  position: relative;
  //display: flex;
  //justify-content: flex-start;
  //align-items: flex-start;
  min-height: ~"calc(100vh - 86px)";
}

article {
  flex: 1;
}

aside {
  position: absolute;
  width: 175px;
  top: 0;
  left: -160px;
  padding-right: 15px;

  .sidebar-block {
    margin-bottom: 25px;

    &__title {
      display: block;
      font-weight: 700;
      font-size: 14px;
      color: @dark-blue;
      padding: 3px 0;
      margin-bottom: 8px;
    }
    &__list {
      list-style: none;

      li {
        margin-bottom: 8px;

        &.current {
          a {
            text-decoration: none;
          }
        }
      }
      a {
        text-decoration: underline;
        font-weight: 500;
        color: @dark-blue;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

.banner {
  width: 100%;
  height: auto;
  margin-bottom: 15px;

  img {
    width: 100%;
    height: auto;
  }
}

.content {
  margin-bottom: 30px;

  & > * {
    margin-bottom: 15px;
  }
  h1, h2, h3, h4, h5, h6 {
    text-align: center;
    font-weight: 700;
    color: @dark-blue;
  }
  h1 {
    font-size: 16px;
  }
  h2 {
    font-size: 14px;
  }
  h3, h4, h5, h6 {
    font-size: 12px;
  }
  ul, ol {
    padding-left: 20px;
  }
  img {
    &.full {
      width: 100%;
    }
    &.left {
      float: left;
      margin-right: 15px;
    }
    &.right {
      float: right;
      margin-left: 15px;
    }
    &.center {
      display: block;
      max-width: 100%;
      margin: 0 auto 12px;
    }
  }
  table {
    max-width: 100%;
    margin: 0 auto 15px;
    border-collapse: collapse;
    background-color: @gray;

    td {
      border: 1px solid @dark-blue;
      padding: 8px 20px;
    }
  }

  .review {
    position: relative;
    background-color: @gray;
    border-radius: 5px;
    padding: 5px 40px 5px 10px;

    &:after {
      display: block;
      content: '';
      position: absolute;
      width: 30px;
      height: 30px;
      right: 10px;
      top: 5px;
      background-image: url('../img/review-icon.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }
}

footer {
  .footer {
    &__copyright {
      font-size: 12px;
      font-weight: 500;
      text-align: center;
      color: @dark-blue;
      padding: 8px 15px;
      background-color: @gray;
    }
  }
}

@import "media";